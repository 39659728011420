import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { GetSessionQuery, useGetSessionLazyQuery, useLoginMutation, useLogoutMutation } from '@graphql';
import { loginStatusVar } from '@context';
import { useToastError } from '@hooks';

export default function useConnection() {
  const loginStatus = useReactiveVar(loginStatusVar);
  const toastError = useToastError();
  const navigate = useNavigate();
  const { t } = useTranslation('authentication');

  const getSelectedAccount = (session: GetSessionQuery['session']) => {
    if (!session || !session.accounts) return null;

    const selectedAccount = session.accounts.find(({ isCurrent }) => isCurrent);

    return selectedAccount || null;
  };

  const [getSession] = useGetSessionLazyQuery({
    onError: toastError,
    onCompleted: ({ session }) => {
      import.meta.env.MODE !== 'production' && console.log(`[{session}]:`, session);
      loginStatusVar({
        ...loginStatus,
        hasAccessToken: true,
        isSessionExpired: false,
        shouldLogFirst: false,
      });
      toast(t('toast.success', { firstname: session?.firstname }));
      loginStatus.redirection && navigate(loginStatus.redirection);
      const account = getSelectedAccount(session);
      if (account?.__typename === 'Spot') {
        navigate('spot');
      }
    },
  });

  const [login] = useLoginMutation({
    onCompleted({ login }) {
      getSession();
    },
    onError: toastError,
  });

  const [logout, { client }] = useLogoutMutation({
    async onCompleted() {
      toast(`👋 Adio !`);

      // clear the cache (unlike resetStore, clearStore does not refetch queries)
      await client.clearStore();

      // make sure to clear the cache for the following queries
      localStorage.removeItem('SPOTS_LAST_CALL');
      localStorage.removeItem('EVENTS_LAST_CALL');

      loginStatusVar({
        hasAccessToken: false,
        isSessionExpired: false,
        shouldLogFirst: false,
        redirection: null,
      });
      navigate('/spots');
    },
  });

  return {
    login,
    logout,
  };
}
